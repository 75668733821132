
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BaseModalType } from "@/models/schoolfinder.enums";
@Component
export default class BaseModal extends Vue {
  BaseModalType = BaseModalType;
  @Watch("showModal")
  onShowModalChange(showModal: boolean) {
    this.controlModalDisplay(showModal);
  }

  @Prop() id!: string;
  @Prop() headerText!: string;
  @Prop() message!: string;
  @Prop({ default: "Yes" }) yesButtonTextContent!: string;
  @Prop({ default: "No" }) noButtonTextContent!: string;
  @Prop({ default: false }) showModal!: boolean;
  @Prop({ default: false }) noCloseOnBackdrop!: boolean;

  @Prop({ default: false }) showData!: boolean;
  @Prop({ default: () => [] }) dataList!: [];

  @Prop({ default: false }) showVideo!: boolean;
  @Prop({ default: "" }) videoSrc!: string;

  @Prop({ default: false }) showMessage!: boolean;
  @Prop({ default: "" }) modalMessage!: boolean;

  @Prop({ default: BaseModalType.None }) modalType!: BaseModalType;

  controlModalDisplay(showModal: boolean) {
    if (showModal) this.$bvModal.show(this.id);
    else this.$bvModal.hide(this.id);
  }

  get customModalBackground() {
    return {
      "modal-background": this.modalType === BaseModalType.Message,
    };
  }
}
