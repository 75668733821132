
import { Vue, Component, Prop } from "vue-property-decorator";
import { mapState } from "vuex";
import HalfdonutProgress from "@/components/HalfdonutProgress.vue";
@Component({
  components: {
    "halfdonut-progress": HalfdonutProgress,
  },
  computed: { ...mapState("app", ["mobileView", "tabletView"]) },
})
export default class BaseInfoViewHalfDoughnut extends Vue {
  @Prop() score!: string;
  @Prop() datapointDesc!: string;
  @Prop() datapointDescAdditonal!: string;
  @Prop({ default: "" }) sidebarInfo!: string;
  @Prop() sidebarDatapoint!: string;
  @Prop({ default: false }) scoreAbsolute!: boolean;

  mobileView!: boolean;
  tabletView!: boolean;

  get scoreFormatted() {
    const result = "";
    if (!isNaN(+this.score)) return result;
    if (this.score === "Not Applicable" && this.mobileView) return "N/A";
    return this.score;
  }

  get customContent() {
    return {
      "pl-3 w-50": this.tabletView,
      "w-60": this.mobileView,
    };
  }

  get customInfoButton() {
    return {
      "ml-2": this.tabletView,
      "": this.mobileView,
    };
  }

  get customHalfDoughnutChart() {
    return {
      "justify-content-center w-50": this.tabletView,
      "ml-auto": this.mobileView,
    };
  }

  get customScoreExceptionContainerSize() {
    return {
      "score-exception-container-size-tablet": this.tabletView,
      "score-exception-container-size-mobile": this.mobileView,
    };
  }

  get customScoreExceptionContainerOverflow() {
    return {
      "score-exception-container-overflow-tablet": this.tabletView,
      "score-exception-container-overflow-mobile": this.mobileView,
    };
  }
}
