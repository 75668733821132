
import { Vue, Component, Watch } from "vue-property-decorator";
@Component({})
export default class App extends Vue {
  /*   @Watch("$route", { immediate: true })
  onRouteChange(route: any) {
    document.title = route.meta.title || "Some Default Title";
  } */

  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
  }

  destroyed() {
    window.removeEventListener("resize", this.onResize);
  }

  onResize() {
    this.$store.dispatch("app/setDeviceDisplayType");
  }
}
