
import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import {routes} from "../routes";
const originalPush = VueRouter.prototype.push as unknown as Promise<Route>
VueRouter.prototype.push = function push(location: RawLocation) {
  return (originalPush as any).call(this, location).catch((err: Error) => err)
}

Vue.use(VueRouter);





const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
