
import { Vue } from "vue-property-decorator";

/**
 * Converts the given enum to a map of the keys to the values.
 * @param enumeration The enum to convert to a map.
 */

export function enumToMap(enumeration: any): Map<string, string | number> {
    const map = new Map<string, string | number>();
    for (const key in enumeration) {
        //TypeScript does not allow enum keys to be numeric
        if (!isNaN(Number(key))) continue;
        const val = enumeration[key] as string | number;
        //TypeScript does not allow enum value to be null or undefined
        if (val !== undefined && val !== null)
            map.set(key, val);
    }
    return map;
}

export enum Grade {
    Select = 0,
    Infant = 1,
    Toddler = 2,
    PK = 3,
    Kindergarten = 4,
    Grade1 = 5,
    Grade2 = 6,
    Grade3 = 7,
    Grade4 = 8,
    Grade5 = 9,
    Grade6 = 10,
    Grade7 = 11,
    Grade8 = 12,
    Grade9 = 13,
    Grade10 = 14,
    Grade11 = 15,
    Grade12 = 16
}

export const gradeArray = [
    { id: 0, name: "--Select--" },
    { id: 1, name: "Infant (Under 1)" },
    { id: 2, name: "Toddler (Ages 1-2)" },
    { id: 3, name: "PK (Ages 3-4)" },
    { id: 4, name: "Kindergarten" },
    { id: 5, name: "Grade 1" },
    { id: 6, name: "Grade 2" },
    { id: 7, name: "Grade 3" },
    { id: 8, name: "Grade 4" },
    { id: 9, name: "Grade 5" },
    { id: 10, name: "Grade 6" },
    { id: 11, name: "Grade 7" },
    { id: 12, name: "Grade 8" },
    { id: 13, name: "Grade 9" },
    { id: 14, name: "Grade 10" },
    { id: 15, name: "Grade 11" },
    { id: 16, name: "Grade 12" },
]

export enum AcademicOffering {
    AdvancedPlacement,
    WorldLanguageImmersion,
    SealOfBiliteracy,
    DualEnrollment,
    JumpStartPathways,
    GiftedPrograms,
    ForeignLanguage
}

export const academicOfferingsArray = [
    { id: "AdvancedPlacement", name: "Advanced Placement" },
    { id: "WorldLanguageImmersion", name: "World language immersion" },
    { id: "SealOfBiliteracy", name: "Seal of Biliteracy" },
    { id: "DualEnrollment", name: "Dual enrollment (college credit)" },
    { id: "JumpStartPathways", name: "Jump Start Pathways (career focused)" },
    { id: "GiftedPrograms", name: "Gifted programs" },
    { id: "ForeignLanguage", name: "Foreign Language" },
]

export enum SchoolTab {
    About,
    AcademicPerformance,
    Spending,
    COVID19Impact,
    Inspections,
    DistrictSpending,
    DistrictCOVID19Impact,
    DisciplineAndAttendance
}

export const schoolTabArray = [
    { id: "About", name: "About" },
    { id: "AcademicPerformance", name: "Academic Performance" },
    { id: "Spending", name: "Spending" },
    { id: "COVID19Impact", name: "COVID-19 Impact" },
    { id: "Inspections", name: "Inspections" },
    { id: "DistrictSpending", name: "District Spending"},
    { id: "DisciplineandAttendance", name: "Attendance/Discipline"}
]

export enum ProfileType {
    EarlyChildhood,
    K12,
    Both
}

export const profileTypeArray = [
    { id: "EarlyChildhood", name: "EE" },
    { id: "K12", name: "K12" },
    { id: "Both", name: "BOTH" },
]

export enum EntityType {
    None,
    School,
    SchoolSystem,
    State
}

export const entityTypeArray = [
    { id: "none", name: "none" },
    { id: "school", name: "school" },
    { id: "district", name: "district" },
    { id: "state", name: "state" },
]

export enum BaseModalType {
    None,
    Data,
    Message,
    Video
}