
import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  name: "HalfdonutProgress",
})
export default class HalfdonutProgress extends Vue {
  @Prop({ default: "0" }) val!: string;
  @Prop({ default: "#008085" }) colors!: string;
  @Prop({ default: false }) scoreAbsolute!: boolean;

  public findsymbol(val: any) {
    return val && (val.includes("<") || val.includes(">") || this.scoreAbsolute) ? parseInt(val) : val + "%";
  }
}
