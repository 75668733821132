
import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class BaseDataTile extends Vue {
  @Prop() tileLabel!: string;
  @Prop({ default: "" }) tileValue!: string;
  @Prop({ default: "" }) tileValueSecond!: string;
  @Prop({ default: "" }) tileLink!: string;
  @Prop({ default: () => [] }) inputList!: string[];
  @Prop({ default: "" }) tileLinkValue!: string;
  @Prop({ default: "" }) tileLinkLabel!: string;

  get showList() {
    return this.inputList && this.inputList.length > 0;
  }
}
