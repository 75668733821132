export abstract class BaseService {

  protected readonly api = process.env.VUE_APP_API_URL;
  public readonly addApiToUrl = process.env.VUE_APP_ADD_API_TO_URL;

  constructor() {
    if(process.env.NODE_ENV === "production")
    {
      this.addApiToUrl = process.env.BASE_URL + process.env.VUE_APP_ADD_API_TO_URL;
    }
  }
}
