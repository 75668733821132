import Vue from "vue";
import App from "./App.vue";
//import "./registerServiceWorker";
import router from "./router";
import store from "./store";
//import VueMeta from 'vue-meta';
import VueBootstrapTypeahead from "vue-bootstrap-typeahead";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import _ from "lodash";
import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from "bootstrap-vue";
import { BToast } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import AsyncComputedPlugin from "vue-async-computed";
import { BSidebar } from "bootstrap-vue";
import "./assets/js/class-component-hooks";
import VueEllipseProgress from "vue-ellipse-progress";
import VueSticky from "vue-sticky";
import VTooltip from "v-tooltip";

/* Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
}); */
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(VueEllipseProgress);
//Vue.use(VueSticky);
Vue.component("b-toast", BToast);
Vue.component("b-sidebar", BSidebar);
//Vue.component("sticky", VueSticky);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(AsyncComputedPlugin);
Vue.use(VTooltip);

Vue.component("vue-bootstrap-typeahead", VueBootstrapTypeahead);
Vue.config.productionTip = false;

Vue.directive("sticky", VueSticky);

const requireComponent = require.context(
  // The relative path of the components folder
  "./components",
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match base component filenames
  /Base[A-Z]\w+\.(vue|js)$/
);

requireComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireComponent(fileName);

  // Get PascalCase name of component
  const componentName = upperFirst(
    camelCase(
      // Gets the file name regardless of folder depth
      fileName
        ?.split("/")
        ?.pop()
        ?.replace(/\.\w+$/, "")
    )
  );

  // Register component globally
  Vue.component(
    componentName,
    // Look for the component options on `.default`, which will
    // exist if the component was exported with `export default`,
    // otherwise fall back to module's root.
    componentConfig.default || componentConfig
  );
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
