import Vue from "vue";
import Vuex from "vuex";
import app from "./modules/app";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    app: {
      namespaced: true,
      state: app.state,
      actions: app.actions,
      mutations: app.mutations,
      getters: app.getters,
    },
  },
});
